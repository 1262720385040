.navbar-vcp {
  margin-bottom: 10px;

  .navbar-brand {
    margin: 9px 0;
    height: 75px;
    width: 177px;
    text-indent: -999em;
    background: url('/img/logo.svg') no-repeat center center;
    background-size: contain;
  }

  .navbar-text {
    color: @navbar-default-color;
  }

  .navbar-nav {
    > li > a {
      margin: 0 8px;
      padding: 6px 8px;
      color: @navbar-default-link-color;
      border-bottom: 3px solid transparent;

      &:hover {
        border-bottom-color: lighten(@brand-primary, 10%);
      }

      &:hover,
      &:focus {
        color: @navbar-default-link-hover-color;
        background: none;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-active-color;
        background: none;
        border-bottom-color: @brand-primary;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
        background: none;
      }
    }
  }

  .navbar-toggle {
    border-color: @navbar-default-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-default-toggle-hover-bg;
    }
    .icon-bar {
      background-color: @navbar-default-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: @navbar-default-border;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-default-link-active-bg;
        color: @navbar-default-link-active-color;
      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: @navbar-default-link-color;
          &:hover,
          &:focus {
            color: @navbar-default-link-hover-color;
            background-color: @navbar-default-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-active-color;
            background-color: @navbar-default-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-disabled-color;
            background-color: @navbar-default-link-disabled-bg;
          }
        }
      }
    }
  }


  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: @navbar-default-link-color;
    &:hover {
      color: @navbar-default-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-default-link-color;
    &:hover,
    &:focus {
      color: @navbar-default-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
      }
    }
  }
}
