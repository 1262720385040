.table-state-licenses {
  .td-expiration-date {
    width: 30%;
  }
}

.table-work-history {
  .td-start-date,
  .td-end-date {
    width: 25%;
  }
}