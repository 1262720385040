@bootstrap-base-path: '../public/lib/bootstrap/';
@bootstrap-less-path: '@{bootstrap-base-path}less/';

// Core variables and mixins
@import "variables.less";
@import "@{bootstrap-less-path}mixins.less";

// Reset and dependencies
@import "@{bootstrap-less-path}normalize.less";
@import "@{bootstrap-less-path}print.less";
//@import "@{bootstrap-less-path}glyphicons.less";

// Core CSS
@import "@{bootstrap-less-path}scaffolding.less";
@import "@{bootstrap-less-path}type.less";
@import "@{bootstrap-less-path}code.less";
@import "@{bootstrap-less-path}grid.less";
@import "@{bootstrap-less-path}tables.less";
@import "@{bootstrap-less-path}forms.less";
@import "@{bootstrap-less-path}buttons.less";

// Components
@import "@{bootstrap-less-path}component-animations.less";
@import "@{bootstrap-less-path}dropdowns.less";
@import "@{bootstrap-less-path}button-groups.less";
@import "@{bootstrap-less-path}input-groups.less";
@import "@{bootstrap-less-path}navs.less";
@import "@{bootstrap-less-path}navbar.less";
@import "@{bootstrap-less-path}breadcrumbs.less";
@import "@{bootstrap-less-path}pagination.less";
@import "@{bootstrap-less-path}pager.less";
@import "@{bootstrap-less-path}labels.less";
@import "@{bootstrap-less-path}badges.less";
@import "@{bootstrap-less-path}jumbotron.less";
@import "@{bootstrap-less-path}thumbnails.less";
@import "@{bootstrap-less-path}alerts.less";
@import "@{bootstrap-less-path}progress-bars.less";
@import "@{bootstrap-less-path}media.less";
@import "@{bootstrap-less-path}list-group.less";
@import "@{bootstrap-less-path}panels.less";
@import "@{bootstrap-less-path}responsive-embed.less";
@import "@{bootstrap-less-path}wells.less";
@import "@{bootstrap-less-path}close.less";

// Components w/ JavaScript
@import "@{bootstrap-less-path}modals.less";
@import "@{bootstrap-less-path}tooltip.less";
@import "@{bootstrap-less-path}popovers.less";
@import "@{bootstrap-less-path}carousel.less";

// Utility classes
@import "@{bootstrap-less-path}utilities.less";
@import "@{bootstrap-less-path}responsive-utilities.less";

// Custom Imports
@import "glyphicons.less";
@import "glyphicons-halflings.less";
@import "navbar-vcp.less";
@import "forms.less";

.glyphicons, .halflings {
  top: 0;
  line-height: inherit;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 80px;

  :after {
    // Preload images
    display: none;
    content: url('../img/loaders/tail-spin.svg');
    content: url('../img/loaders/tail-spin-white.svg');
  }
}

header {
  position: relative;
  padding-top: 10px;

  .auth {
    position: absolute;
    top: 72px;
    right: 20px;
  }
}

@media (min-width: 768px) {
  .navbar-vcp .navbar-nav {
    padding-top: 60px;
  }

  header .auth {
    top: 30px;
    right: 30px;
  }
}

.navbar-toggle {
  border-color: #ccc;
}

.icon-bar {
  border-color: #ccc;
  background-color: #ccc;
}

footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 20px;
  height: 20px;
  width: 100%;
  color: #999;
  font-size: 10px;

  a {
    color: #777;
  }
}

.page-header {
  margin-top: 0;
  font-size: 13px;
}
.page-header h1 {
  font: 24px/1.5 Verdana, Helvetica, sans-serif;
}
.page-header.inverted {
  background: #000;
  color: #b5b5b5;
  min-height: 130px;
}
.page-header.inverted h1 {
  color: #336698;
}

.btn.waiting {
  color: transparent;
  background-image: url('../img/loaders/tail-spin-white.svg');
  background-size: 1.6em;
  background-repeat: no-repeat;
  background-position: center center;
}

.btn-link.waiting {
  background-image: url('../img/loaders/tail-spin.svg');
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: transparent;
    }
  }
}

.loader {
  height: 75px;
  background-image: url('../img/loaders/tail-spin.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.page-welcome {
  .page-header {
    padding-bottom: 0;
  }
}
